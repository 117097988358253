<script lang="ts" setup>
const authService = useAuthService()
const authStore = useAuthStore()

function fetchProfile() {
  authService.fetchProfile()
}
</script>

<template>
  <div>
    <!-- eslint-disable -->
    Header Brochureware
    <a @click="fetchProfile" @keyup.enter="fetchProfile">Fetch me</a>
    <Anchor v-if="authStore.isLoggedIn" :to="authStore.accountPath">My account</Anchor>
    <!-- eslint-enable -->

    <slot />
  </div>
</template>
